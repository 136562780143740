import React from "react";
import "./Login.css";

// Use the API URL from the environment variable (for auth redirects)
const API_URL = process.env.REACT_APP_API_URL || "https://link.pandarust.xyz/api";

const Login = () => {
  return (
    <div className="link-account-page">
      <div className="content-container">
        <h1 className="page-title">Panda Linking System</h1>
        <div className="accounts-container">
          {/* Steam Card */}
          <div className="account-card steam" onClick={() => window.location.href = `${API_URL.replace("/api", "")}/auth/steam`}>
            <img 
              src="https://upload.wikimedia.org/wikipedia/commons/8/83/Steam_icon_logo.svg" 
              alt="Steam Logo" 
              className="account-avatar"
            />
            <div className="account-info">
              <p className="account-id">Connect your steam account</p>
            </div>
            <button className="link-button">Login with steam</button>
          </div>
          {/* Discord Card (disabled) */}
          <div className="account-card discord">
            <img 
              src="https://i.imgur.com/EglZIYa.png" 
              alt="Discord Logo" 
              className="account-avatar"
            />
            <div className="account-info">
              <p className="account-id">Connect your discord account</p>
            </div>
            <button className="link-button discord" disabled>Login with discord</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
