import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Success.css";

// Use the API URL from the environment variable
const API_URL = process.env.REACT_APP_API_URL || "https://link.pandarust.xyz/api";

const Success = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    axios
      .get(`${API_URL}/user`, { withCredentials: true })
      .then((res) => setUser(res.data))
      .catch(() => window.location.href = "/"); // Redirect if not logged in
  }, []);

  const handleUnlink = async () => {
    try {
      await axios.post(`${API_URL}/unlink`, {}, { withCredentials: true });
      window.location.href = "/";
    } catch (err) {
      console.error("Error unlinking account:", err);
    }
  };

  return (
    <div className="success-page">
      <div className="content-container">
        <h1 className="page-title">Account Linked Successfully</h1>
        {user && (
          <div className="accounts-container">
            {/* Steam Card */}
            <div className="account-card steam">
              <img 
                src={user.steamAvatar} 
                alt="Steam Avatar" 
                className="account-avatar" 
              />
              <div className="account-info">
                <p className="account-name">Steam Name: {user.steamName}</p>
                <p className="account-id">Steam ID: {user.steamID}</p>
              </div>
            </div>
            {/* Discord Card */}
            <div className="account-card discord1">
              <img 
                src={user.discordAvatar} 
                alt="Discord Avatar" 
                className="account-avatar" 
              />
              <div className="account-info">
                <p className="account-name">Discord Name: {user.discordName}</p>
                <p className="account-id">Discord ID: {user.discordID}</p>
              </div>
            </div>
          </div>
        )}
        {user && (
          <div className="action-buttons">
            <button className="link-button unlink-button" onClick={handleUnlink}>
              Unlink Account
            </button>
            <a href="https://discord.gg/pandarust" target="_blank" rel="noopener noreferrer">
              <button className="link-button join-discord-button">
                Join Discord Server
              </button>
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default Success;
