import React, { useEffect, useState } from "react";
import axios from "axios";
import "./LinkDiscord.css";

// Use the API URL from the environment variable
const API_URL = process.env.REACT_APP_API_URL || "https://link.pandarust.xyz/api";

const LinkDiscord = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    axios
      .get(`${API_URL}/user`, { withCredentials: true })
      .then((res) => setUser(res.data))
      .catch(() => (window.location.href = "/")); // Redirect back if not logged in
  }, []);

  return (
    <div className="link-discord-page">
      <div className="content-container">
        <h1 className="page-title">Connect your Discord account</h1>
        <div className="accounts-container">
          {/* Steam Card (Already linked) */}
          <div className="account-card steam">
            {user && (
              <img
                src={user.steamAvatar}
                alt="Steam Avatar"
                className="account-avatar"
              />
            )}
            <div className="account-info">
              <p className="account-id">Your Steam Account Connected</p>
            </div>
            <button className="link-button connected" disabled>
              Connected
            </button>
          </div>
          {/* Discord Card (Active) */}
          <div className="account-card discord1">
            <img
              src="https://i.imgur.com/EglZIYa.png"
              alt="Discord Logo"
              className="account-avatar"
            />
            <div className="account-info">
              <p className="account-id">Connect your Discord Account</p>
            </div>
            <a href={`${API_URL.replace("/api", "")}/auth/discord`}>
              <button className="link-button">
                Login with Discord
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LinkDiscord;
